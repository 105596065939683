/* Provide sufficient contrast against white background */

.main-content {
    padding-bottom: 61px;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.icon {
    margin-right: 8px;
}

.page-icon {
    max-height: 32px;
}

.crumb-icon {
    max-height: 20px;
}

.selectable {
    cursor: pointer;
}

.non-link a, .non-link a:hover {
    color: black;
    text-decoration: none;
}

ul.simple-list, ol.simple-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

input.number-sm {
    width: 5em;
    display: inline-block;
}

.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 2.1em;
    line-height: 2.1em;
    margin: 0.4em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}
